import React, { useRef, useState } from "react";
import "./Detail.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Carousel } from 'react-responsive-carousel';
import CustomCarouselItem from "./CustomCarouselItem";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { after } from "underscore";
import FullScreenCarousel from "./FullscreenCarousel";

function rotateArray(array, index) {
  if (index < 0 || index >= array.length) {
    // Index out of bounds, handle error or return the original array as needed
    return array;
  }

  const firstPart = array.slice(0, index);
  const secondPart = array.slice(index);

  return secondPart.concat(firstPart);
}

export function Detail(props) {

  const carouselRef = useRef()
  const onAllLoad = after(props.item.length - 1, () => {
    carouselRef.current.className += " projets-container-loaded"
  });
  const [clickedImage, setClickedImage] = useState(0)
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenClick = (index) => {
    setIsFullScreen(true);
    setClickedImage(index)
    console.log(index)
  };

  return (
    <div className="detail-container">

      <div className="text-container">
        <div className="detail-title">{props.item[0].nom.toUpperCase()}</div>
        <div className="detail-ville">
          <div className="ville-p">{props.item[0].ville} </div>
          <div className="annee-p">{props.item[0].annee}</div>
        </div>
        <Carousel onClickItem={handleFullScreenClick}
          showThumbs={false}
          emulateTouch
          dynamicHeight
          infiniteLoop
          showStatus={false}
          showIndicators={false}
          className="carousel-detail"
        >
          {props.item.slice(1).map((items, index) =>
            <CustomCarouselItem src={items.nom} onAllLoad={onAllLoad} index={index} />
          )}
        </Carousel>

        <div className="description" dangerouslySetInnerHTML={{ __html: props.item[0].description }} />


      </div>
      {/* Display the full-screen carousel when isFullScreen is true */}
      {isFullScreen && (
        <FullScreenCarousel image={props.item[clickedImage + 1]} onClose={() => setIsFullScreen(false)} />
      )}
      <Link className="leave" to="/projets">
        <img src={require("./close.png")} alt="" className="leave"></img>
      </Link>
    </div>
  );
}
