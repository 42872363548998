import React from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./FullscreenCarousel.css"
const FullScreenCarousel = ({ image, onClose }) => {
    console.log(image)
    return (
        <>
            <div className="fullscreen-carousel" >

                {/* Render carousel content here */}
                <div style={{ margin: 'auto' }}>
                    <TransformWrapper limitToBounds={true} smooth >
                        <TransformComponent wrapperStyle={{ overflow: "visible" }} >
                            <img className="image-display" src={image.nom} alt='zoom' />
                        </TransformComponent>
                    </TransformWrapper>
                </div>
            </div>
            <div className='fullscreen-backdrop' onClick={onClose}></div>
        </>
    );
};

export default FullScreenCarousel;
