import { useState } from "react"
import 'react-spinner-animated/dist/index.css'
import { Spinner } from 'react-spinner-animated';
import "./Image.css"

export default function Image({ src, className, load, spinner = false }) {
    const [isLoaded, setIsLoaded] = useState(false)
    const imageLoaded = () => {
        setIsLoaded(true)
        load()
    }
    return (
        <>
            {!isLoaded || spinner ?
                <div className="spinner-loading">
                    <Spinner text={"Chargement..."} center={false} height={"100px"} />
                </div> : ''}
            {isLoaded &&
                <img src={src} alt="Zoomable" className={className} onLoad={imageLoaded} />
            }
            <img src={src} style={{ display: 'none' }} className={className} onLoad={imageLoaded} />
        </>
    )
}