import React from 'react';

class RepositionableComponent extends React.Component {
    render() {
        const { children } = this.props;

        return (
            <div style={{ position: 'absolute' }}>
                {children}
            </div>
        );
    }
}
export default RepositionableComponent;
